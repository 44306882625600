import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function ReturnPolicy() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <h1 className="text-xl font-bold mb-6">Return and Refund Policy</h1>

        <p className="mt-2 text-base">
          Thank you for subscribing to our services at RealignMind.com, operated
          by Realign LLC. We strive to ensure our customers are satisfied with
          our services. However, if you are not entirely satisfied with your
          purchase, we're here to help.
        </p>

        <h2 className="text-lg font-semibold mt-4">Refunds</h2>
        <p className="mt-2 text-base">
          You have 14 calendar days from the date of billing to request a
          refund. To be eligible for a refund, you should not have extensively
          used the service during this period. Please contact our support team
          to initiate a refund process.
        </p>

        <h2 className="text-lg font-semibold mt-4">Contact Us</h2>
        <p className="mt-2 text-base">
          If you have any questions about our return and refund policy, please
          get in touch with us at <Link to="/contact">Contact Us</Link>.
        </p>

        <p className="mt-2 text-base">Last Updated: December 05, 2024</p>
      </main>

      <Footer />
    </div>
  );
}

export default ReturnPolicy;
