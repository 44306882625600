// hooks/useAuthValidation.js
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

export const useAuthValidation = () => {
  const [isValidated, setIsValidated] = useState(false);
  const { user, isSubscribed } = useAuth();

  useEffect(() => {
    const validateAuth = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Please login to continue");
        return false;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          toast.error("Your session has expired. Please login again.");
          return false;
        }

        if (!user || !isSubscribed) {
          return false;
        }

        return true;
      } catch (error) {
        console.error("Token validation error:", error);
        return false;
      }
    };

    setIsValidated(validateAuth());
  }, [user, isSubscribed]);

  return isValidated;
};