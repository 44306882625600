import React from "react";
import "./styles/Home.css";
import Footer from "./Footer";
import Header from "./Header";

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <Header />
      <main className="terms-of-service-main mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <h1 className="text-xl font-bold mb-6">Terms of Service</h1>
        <h2 className="text-lg font-semibold mt-4">1. Overview</h2>
        <p className="mt-2 text-base">
          These Terms of Service ("Terms") govern your access to and use of our
          online platform, RealignMind.com, operated by Realign LLC ("we," "us,"
          or "our"). By accessing or using the Platform, you agree to be bound
          by these Terms and our Privacy Policy. If you do not agree to these
          Terms, you must not access or use the Platform.
        </p>
        Age Requirement: You must be at least 18 years old, or the legal age of
        majority in your jurisdiction, to use the Platform. If you are under 18,
        you may only use the Platform with the involvement and consent of a
        parent or legal guardian.
        <h2 className="text-lg font-semibold mt-4">2. Program Description</h2>
        <p className="mt-2 text-base">
          RealignMind.com provides an online self-guided mental health program
          ("the Program") based on the principles of Eye Movement
          Desensitization and Reprocessing ("EMDR"). The Program is designed to
          assist individuals in managing mental health conditions such as
          trauma, post-traumatic stress disorder (PTSD), depression, anxiety,
          and addictions.
        </p>
        <p className="mt-2 text-base">
          We do not claim that the Program is equivalent to traditional
          in-person therapy or that it has been endorsed by any professional
          organization. Any testimonials or endorsements by practitioners are
          their personal opinions and do not represent official endorsements.
        </p>
        <h2 className="text-lg font-semibold mt-4">3. Suitability of Use</h2>
        <p className="mt-2 text-base">
          Important Notice: The Program may not be suitable for everyone. It is
          not a substitute for professional medical advice, diagnosis, or
          treatment.
        </p>
        <h2 className="text-lg font-semibold mt-4">4. Emergency Disclaimer:</h2>
        <p className="mt-2 text-base">
          <strong>
            IF YOU ARE THINKING ABOUT SUICIDE, CONSIDERING HARMING YOURSELF OR
            OTHERS, OR FEEL THAT ANY PERSON MAY BE IN DANGER, YOU MUST
            IMMEDIATELY CALL EMERGENCY SERVICES IN YOUR AREA AND NOTIFY THE
            RELEVANT AUTHORITIES. THE PLATFORM IS NOT DESIGNED FOR CRISIS
            INTERVENTION OR EMERGENCY SITUATIONS.
          </strong>
        </p>
        <p className="mt-2 text-base">
          Before using the Program, consult with a qualified healthcare
          professional to determine if it is appropriate for your specific
          needs.
        </p>
        <h2 className="text-lg font-semibold mt-4">
          4. Disclaimer and Limitation of Liability
        </h2>
        <p className="mt-2 text-base">You understand and agree that:</p>
        <p className="mt-2 text-base">
          The Platform and the Program are provided "as is" and "as available"
          without warranties of any kind, either express or implied. We do not
          warrant that the Program will meet your requirements or that its
          operation will be uninterrupted or error-free. We disclaim all
          warranties, including but not limited to warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. Limitation of Liability:
        </p>
        <p className="mt-2 text-base">
          To the maximum extent permitted by law, Realign LLC shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including but not limited to loss of profits, data,
          use, goodwill, or other intangible losses resulting from:
        </p>
        <p className="mt-2 text-base">
          Your access to or use of or inability to access or use the Platform.
          Any conduct or content of any third party on the Platform.
          Unauthorized access, use, or alteration of your transmissions or
          content. Our total liability to you for any claim arising out of or
          relating to these Terms or your use of the Platform shall not exceed
          the amount you have paid to us in the three (3) months preceding the
          claim.
        </p>
        <h2 className="text-lg font-semibold mt-4">
          5. Account Setup and Access
        </h2>
        <h3>5.1 Registration</h3>
        <p className="mt-2 text-base">
          To access certain features of the Platform, you must create an account
          ("Account"). You agree to:
        </p>
        <blockquote>
          Provide accurate, current, and complete information during
          registration. Maintain and promptly update your information as
          necessary. Keep your login credentials confidential and secure.
        </blockquote>
        <h3>5.2 Account Security</h3>
        <p className="mt-2 text-base">
          You are responsible for all activities that occur under your Account.
          You agree to notify us immediately of any unauthorized use of your
          Account or any other breach of security.
        </p>
        <p className="mt-2 text-base">
          We will not be liable for any loss or damage arising from your failure
          to protect your Account or password.
        </p>
        <h3>5.3 Account Termination</h3>
        <p className="mt-2 text-base">
          We reserve the right to suspend or terminate your Account or access to
          the Platform at our discretion, without notice or liability, for any
          reason, including if we believe you have violated these Terms.
        </p>
        <h2 className="text-lg font-semibold mt-4">6. Intellectual Property</h2>
        <p className="mt-2 text-base">
          All content on the Platform, including but not limited to text,
          graphics, logos, images, audio clips, video clips, software, and data
          compilations ("Content"), is the property of Realign LLC or its
          content suppliers and is protected by United States and international
          copyright laws.
        </p>
        <p className="mt-2 text-base">You may not:</p>
        <p className="mt-2 text-base">
          Copy, reproduce, distribute, transmit, broadcast, display, sell,
          license, or otherwise exploit any Content without our prior written
          consent. Use any trademarks, service marks, logos, or trade names
          displayed on the Platform without our written permission.
        </p>
        <h2 className="text-lg font-semibold mt-4">
          7. Subscription and Billing
        </h2>
        <h3>7.1 Subscription Plans</h3>
        <p className="mt-2 text-base">
          Access to the Program is provided on a subscription basis. By
          subscribing, you agree to pay all fees associated with the selected
          plan.
        </p>
        <h3>7.2 Free Trial</h3>
        <p className="mt-2 text-base">
          We may offer a free trial period for new subscribers. During the trial
          period, you may cancel at any time. If you do not cancel before the
          trial ends, you will be automatically enrolled in the subscription
          plan you selected at registration, and your payment method will be
          charged.
        </p>
        <h3>7.3 Billing and Payment</h3>
        <p className="mt-2 text-base">
          You authorize us to charge your provided payment method for the
          subscription fees. You are responsible for providing valid payment
          information and keeping it up to date.
        </p>
        <h3>7.4 Automatic Renewal</h3>
        <p className="mt-2 text-base">
          Your subscription will automatically renew at the end of each billing
          cycle unless you cancel before the renewal date. Renewal fees will be
          charged at the then-current rate.
        </p>
        <h3>7.5 Cancellation and Refunds</h3>
        <p className="mt-2 text-base">
          You may cancel your subscription at any time through your Account
          settings. Refunds for any unused portion of your subscription are at
          our sole discretion, except where required by law.
        </p>
        <h3>7.6 Price Changes</h3>
        <p className="mt-2 text-base">
          We reserve the right to change subscription fees at any time. We will
          provide notice of any fee changes by posting the new fees on the
          Platform or by notifying you via email.
        </p>
        <h2 className="text-lg font-semibold mt-4">8. Privacy and Security</h2>
        <p className="mt-2 text-base">
          Your privacy is important to us. Please review our Privacy Policy to
          understand how we collect, use, and disclose information about you.
        </p>
        <p className="mt-2 text-base">
          By using the Platform, you consent to all actions taken by us with
          respect to your information in compliance with the Privacy Policy.
        </p>
        <h2 className="text-lg font-semibold mt-4">
          9. Associated Services and Third-Party Content
        </h2>
        <h3>9.1 Associated Services</h3>
        <p className="mt-2 text-base">
          We may offer additional services or products ("Associated Services")
          that complement the Program. These may be provided by us or third
          parties. Your use of Associated Services is voluntary and subject to
          separate terms and conditions.
        </p>
        <h3>9.2 Third-Party Content</h3>
        <p className="mt-2 text-base">
          The Platform may contain links to third-party websites, services, or
          resources ("Third-Party Content"). We do not endorse and are not
          responsible for Third-Party Content. Your access and use of
          Third-Party Content are at your own risk.
        </p>
        <h2 className="text-lg font-semibold mt-4">10. Indemnification</h2>
        <p className="mt-2 text-base">
          You agree to indemnify, defend, and hold harmless Realign LLC, its
          affiliates, and their respective officers, directors, employees,
          agents, licensors, and suppliers from and against all claims, losses,
          expenses, damages, and costs, including reasonable attorneys' fees,
          arising out of or relating to:
        </p>
        <blockquote>
          Your use of the Platform or the Program. Your violation of these
          Terms. Your violation of any rights of another.
        </blockquote>
        <h2 className="text-lg font-semibold mt-4">11. Important Notes</h2>
        <h3>11.1 Changes to the Terms</h3>
        <p className="mt-2 text-base">
          We may modify these Terms at any time by posting the revised Terms on
          the Platform. The changes will be effective immediately upon posting.
          Your continued use of the Platform after the posting of the revised
          Terms constitutes your acceptance of the changes.
        </p>
        <h3>11.2 Governing Law</h3>
        <p className="mt-2 text-base">
          These Terms and any dispute arising out of or relating to these Terms
          or the Platform will be governed by the laws of the State of [Your
          State], without regard to its conflict of law provisions.
        </p>
        <h3>11.3 Dispute Resolution</h3>
        <p className="mt-2 text-base">
          Any disputes arising under or in connection with these Terms shall be
          resolved exclusively through binding arbitration conducted in [Your
          City], [Your State], in accordance with the rules of the American
          Arbitration Association.
        </p>
        <h3>11.4 Entire Agreement</h3>
        <p className="mt-2 text-base">
          These Terms, along with our Privacy Policy, constitute the entire
          agreement between you and Realign LLC regarding the Platform and
          supersede all prior agreements.
        </p>
        <h3>11.5 Severability</h3>
        <p className="mt-2 text-base">
          If any provision of these Terms is held invalid or unenforceable, such
          provision shall be struck and the remaining provisions shall be
          enforced.
        </p>
        <h3>11.6 Waiver</h3>
        <p className="mt-2 text-base">
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights.
        </p>
        <h2 className="text-lg font-semibold mt-4">12. Contact Information</h2>
        <p className="mt-2 text-base">
          If you have any questions or concerns about these Terms, please
          contact us at:
        </p>
        <p className="mt-2 text-base">Email: help@realignmind.com</p>
      </main>

      <Footer />
    </div>
  );
}

export default TermsOfService;
