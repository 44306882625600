import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

function UserProfile() {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState(null);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get("/api/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Profile data received:", response.data); // Debug log
        setProfileData(response.data);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleManageSubscription = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/create-portal-session",
        { customer_id: user.stripe_customer_id },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        console.error("No URL found in response");
      }
    } catch (error) {
      console.error("Failed to create portal session:", error);
    }
  };

  return (
    <div className="user-profile flex flex-col min-h-screen">
      <Header />
      <main className="user-profile-main flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        {profileData ? (
          <>
            <p>Welcome, {profileData.first_name || "User"}!</p>
            <p>
              Subscription Status:{" "}
              {profileData.subscription_status || "Unknown"}
            </p>
            <p>
              Subscription Ends: {profileData.subscription_end_date || "N/A"}
            </p>
            {/* Show button regardless of subscription status */}
            <button
              className="subscribe-button"
              onClick={handleManageSubscription}
            >
              Manage Subscription
            </button>
          </>
        ) : (
          <p>Loading profile...</p>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default UserProfile;
