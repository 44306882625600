import React, { useState, useContext } from "react";
import DotContext from "../contexts/DotContext";

const Sidebar = ({
  isVisible,
  closeSidebar,
  dotColor,
  setDotColor,
  bgColor,
  setBgColor,
  dotSize,
  setDotSize,
  dotSpeed,
  setDotSpeed,
  setExitSession,
}) => {
  const { soundFile, setSoundFile, mute, setMute } = useContext(DotContext); // Use DotContext for sound settings
  const [selectedSound, setSelectedSound] = useState(soundFile); // Local state for the sound selector

  const handleSoundChange = (e) => {
    const newSound = e.target.value;
    setSelectedSound(newSound);
    setSoundFile(newSound);

    // Play the selected sound immediately
    const audio = new Audio(newSound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  const toggleMute = () => {
    setMute(!mute);
  };

  const maxSpeedValue = 240;
  const minSpeedValue = 60;

  const handleSpeedChange = (e) => {
    const newSpeedValue =
      maxSpeedValue - parseFloat(e.target.value - minSpeedValue);
    setDotSpeed(newSpeedValue);
  };

  const handleExitSession = () => {
    setExitSession(true);
    closeSidebar();
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-72 bg-background shadow-lg transition-transform duration-300 z-50 transform ${
        isVisible ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <button
        onClick={closeSidebar}
        className="absolute top-0 right-0 p-2 text-black text-2xl bg-transparent"
      >
        &times;
      </button>

      <div className="p-4">
        <div className="mb-4">
          <p className="font-semibold">Dot Color:</p>
          <div className="flex">
            {["#FFB997", "#FFF8F0", "#333333", "#843B62"].map((color) => (
              <button
                key={color}
                className="w-5 h-5 mx-2 border rounded-full focus:outline-none"
                style={{ backgroundColor: color }}
                onClick={() => setDotColor(color)}
              />
            ))}
          </div>
        </div>

        <div className="mb-4">
          <p className="font-semibold">Background Color:</p>
          <div className="flex">
            {["#FFB997", "#FFF8F0", "#333333", "#843B62"].map((color) => (
              <button
                key={color}
                className="w-5 h-5 mx-2 border rounded-full focus:outline-none"
                style={{ backgroundColor: color }}
                onClick={() => setBgColor(color)}
              />
            ))}
          </div>
        </div>

        <div className="mb-4">
          <p className="font-semibold">Circle Size:</p>
          <input
            type="range"
            min="10"
            max="100"
            value={dotSize}
            onChange={(e) => setDotSize(e.target.value)}
            className="w-full accent-accent hover:accent-primary"
          />
        </div>

        <div className="mb-4">
          <p className="font-semibold">Ball Speed:</p>
          <input
            type="range"
            min={50}
            max={300}
            step="1"
            value={240 - (dotSpeed - 60)}
            onChange={handleSpeedChange}
            className="w-full accent-accent hover:accent-primary"
          />
        </div>

        {/* Sound Selection */}
        <div className="mb-4">
          <p className="font-semibold">Select Sound:</p>
          <select
            value={selectedSound}
            onChange={handleSoundChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="/sounds/Synth_Tick_C_lo.wav">Click 1</option>
            <option value="/sounds/Synth_Block_E_hi.wav">Click 2</option>
            <option value="/sounds/Perc_Clackhead_lo.wav">Click 3</option>
            {/* Add more options as needed */}
          </select>
        </div>
        {/* Mute Toggle */}
        <div className="mb-4">
          <p className="font-semibold">Mute Sound:</p>
          <button
            onClick={toggleMute}
            className={`w-full py-2 px-4 font-bold rounded ${
              mute ? "bg-red-500" : "bg-accent"
            } text-white`}
          >
            {mute ? "Unmute" : "Mute"}
          </button>
        </div>
        <div className="mt-auto">
          <button
            onClick={handleExitSession}
            className="w-full bg-accent text-white font-bold py-2 rounded hover:bg-primary transition"
          >
            Exit Session
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
