import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import FullScreenNav from "./FullScreenNav";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Header() {
  const { user, logout } = useAuth();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className="flex justify-between items-center bg-background p-4">
      <Link to="/" className="font-bold text-2xl text-primary">
        RealignMind
      </Link>

      {/* Hamburger Menu Button */}
      <button
        onClick={toggleNav}
        className="flex items-center justify-center md:hidden"
        aria-label="Toggle navigation"
      >
        {isNavVisible ? (
          <XMarkIcon className="h-8 w-8 text-black" />
        ) : (
          <Bars3Icon className="h-8 w-8 text-accent" />
        )}
      </button>

      {/* FullScreenNav Component */}
      <FullScreenNav isVisible={isNavVisible} closeNav={toggleNav} />

      {/* Desktop Navigation Menu */}
      <nav className="hidden md:flex">
        <ul className="flex space-x-5">
          <li>
            <Link to="/about-emdr" className="text-secondary hover:text-white">
              What is EMDR?
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-secondary hover:text-white">
              About Us
            </Link>
          </li>
          {!user && (
            <>
              <li>
                <Link
                  to="/subscribe"
                  className="text-secondary hover:text-white"
                >
                  Subscribe
                </Link>
              </li>
              <li>
                <Link to="/login" className="text-secondary hover:text-white">
                  Login
                </Link>
              </li>
            </>
          )}
          {user && (
            <>
              <li>
                <Link
                  to="/sessions"
                  className="text-secondary hover:text-white"
                >
                  EMDR Session
                </Link>
              </li>
              <li className="relative group">
                <Link to="/profile" className="text-secondary hover:text-white">
                  Profile <span className="inline">▼</span>
                </Link>
                <ul className="absolute right-0 bg-background border border-gray-300 rounded shadow-lg hidden group-hover:block">
                  <li>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-secondary hover:text-white hover:bg-accent"
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/session-history"
                      className="block px-4 py-2 text-secondary hover:text-white hover:bg-accent"
                    >
                      Session History
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={logout}
                      className="block w-full text-left px-4 py-2 text-secondary hover:text-white hover:bg-accent"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
