import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

function SessionHistory() {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get("/api/sessions/history", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setSessions(response.data.session_history);
      } catch (error) {
        setError("Error fetching session history");
        console.error("Error fetching history:", error);
      }
    };
    fetchSessions();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="session-history-main flex-grow mx-auto p-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <h1 className="text-2xl font-bold mb-6">Your EMDR Session History</h1>
        {error && <p className="text-red-500">{error}</p>}
        {!error && sessions.length === 0 && <p>No sessions found.</p>}
        <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4 mt-4">
          {sessions.map((session) => (
            <div
              className="session-card bg-gray-50 border border-gray-300 rounded-lg p-4 shadow-md"
              key={session.session_id}
            >
              <h2 className="font-semibold text-lg">{session.session_type}</h2>
              <p>
                <strong>Start Time:</strong>{" "}
                {new Date(session.session_start).toLocaleString()}
              </p>
              <p>
                <strong>End Time:</strong>{" "}
                {session.session_end
                  ? new Date(session.session_end).toLocaleString()
                  : "In Progress"}
              </p>
              <p>
                <strong>Mood Pre:</strong> {session.mood_pre}
              </p>
              <p>
                <strong>Mood Post:</strong> {session.mood_post}
              </p>
              <p>
                <strong>Comments Before:</strong> {session.comment_pre}
              </p>
              <p>
                <strong>Comments After:</strong> {session.comment_post}
              </p>
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default SessionHistory;
