import React, { useEffect, useRef, useState } from "react";
import SoundPlayer from "./SoundPlayer";
import "./styles/DotMovement.css";

const DotMovement = ({
  dotColor,
  dotSize,
  bgColor,
  dotSpeed,
  soundFile,
  mute,
  isPaused,
  shouldRun,
  onCompletion,
}) => {
  const dotRef = useRef(null);
  const [cycles, setCycles] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [panningPosition, setPanningPosition] = useState(0);

  // Calculate the duration for a half cycle (left->right or right->left)
  // Full cycle: left->right->left = 2 * singleBounceDuration
  const singleBounceDuration = ((dotSpeed * 1000) / 42 / 2).toFixed(3);

  useEffect(() => {
    const currentDot = dotRef.current;
    if (currentDot && shouldRun) {
      currentDot.style.animation = `moveDot ${singleBounceDuration * 2}ms linear infinite`;
      currentDot.style.animationPlayState = isPaused ? "paused" : "running";
    }
  }, [dotSpeed, shouldRun, isPaused, singleBounceDuration]);

  const playSound = (side) => {
    // side = 'left' or 'right'
    setPanningPosition(side === "right" ? 1 : -1);
    setIsPlaying(true);
    console.log(`Playing sound on ${side} speaker`);
  };

  const handleAnimationStart = () => {
    // Animation starts at left edge: play left edge sound
    playSound("left");

    // Schedule right edge sound halfway through the cycle
    setTimeout(() => {
      if (shouldRun && !mute) {
        playSound("right");
      }
    }, singleBounceDuration); // half cycle time
  };

  const handleAnimationIteration = () => {
    // Each iteration completes a full cycle: left->right->left
    const newCycles = cycles + 1;
    setCycles(newCycles);

    console.log(`Cycle ${newCycles} completed`);

    // Play left edge sound again (start of next cycle)
    playSound("left");

    // Schedule right edge sound again at half cycle
    setTimeout(() => {
      if (shouldRun && !mute) {
        playSound("right");
      }
    }, singleBounceDuration);
  };

  useEffect(() => {
    const currentDot = dotRef.current;
    if (currentDot) {
      currentDot.addEventListener("animationstart", handleAnimationStart);
      currentDot.addEventListener(
        "animationiteration",
        handleAnimationIteration,
      );
    }

    return () => {
      if (currentDot) {
        currentDot.removeEventListener("animationstart", handleAnimationStart);
        currentDot.removeEventListener(
          "animationiteration",
          handleAnimationIteration,
        );
      }
    };
  }, [cycles, shouldRun, mute, singleBounceDuration]);

  useEffect(() => {
    if (cycles >= 42) {
      console.log("42 cycles reached. Completing session.");
      if (onCompletion) onCompletion();
      setCycles(0); // Reset cycles if needed
    }
  }, [cycles, onCompletion]);

  return (
    <div
      className="dot-movement-container"
      style={{ backgroundColor: bgColor }}
    >
      <div
        ref={dotRef}
        className="dot"
        style={{
          backgroundColor: dotColor,
          width: `${dotSize}px`,
          height: `${dotSize}px`,
        }}
      />
      <SoundPlayer
        key={soundFile}
        soundFile={soundFile}
        isPlaying={isPlaying}
        mute={mute}
        panningPosition={panningPosition}
        onEnd={() => setIsPlaying(false)}
      />
    </div>
  );
};

export default DotMovement;
