// FullScreenNav.js
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

const FullScreenNav = ({ isVisible, closeNav }) => {
    const { user, logout } = useAuth();

    return (
        <div
            className={`fixed inset-0 bg-background z-50 transition-transform transform ${
                isVisible ? "translate-x-0" : "translate-x-full"
            }`}
        >
            {/* Close Button */}
            <button
                onClick={closeNav}
                className="absolute top-5 right-5 bg-transparent border-none cursor-pointer z-50"
                aria-label="Close navigation"
            >
                <XMarkIcon className="h-8 w-8 text-secondary" />
            </button>

            {/* Navigation Items */}
            <nav className="flex flex-col items-center justify-center h-full space-y-8">
                <ul className="text-center">
                    <li className="text-2xl">
                        <Link to="/" onClick={closeNav}>
                            Home
                        </Link>
                    </li>
                    <li className="text-2xl">
                        <Link to="/about-emdr" onClick={closeNav}>
                            What Is EMDR?
                        </Link>
                    </li>
                    <li className="text-2xl">
                        <Link to="/about" onClick={closeNav}>
                            About Us
                        </Link>
                    </li>
                    {!user && (
                        <>
                            <li className="text-2xl">
                                <Link to="/subscribe" onClick={closeNav}>
                                    Subscribe
                                </Link>
                            </li>
                            <li className="text-2xl">
                                <Link to="/login" onClick={closeNav}>
                                    Login
                                </Link>
                            </li>
                        </>
                    )}
                    {user && (
                        <>
                            <li className="text-2xl">
                                <Link to="/sessions" onClick={closeNav}>
                                    EMDR Session
                                </Link>
                            </li>
                            <li className="text-2xl">
                                <Link to="/profile" onClick={closeNav}>
                                    Profile
                                </Link>
                            </li>
                            <li className="text-2xl">
                                <Link to="/session-history" onClick={closeNav}>
                                    Session History
                                </Link>
                            </li>
                            <li className="text-2xl">
                                <button
                                    onClick={() => {
                                        logout();
                                        closeNav();
                                    }}
                                >
                                    Logout
                                </button>
                            </li>
                        </>
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default FullScreenNav;
