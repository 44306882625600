import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get("/api/verify-token", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data.user);
        setIsSubscribed(response.data.user.is_subscribed);
      } catch (error) {
        if (error.response) {
          console.error(
            "Server responded with a status:",
            error.response.status,
            "Response data:",
            error.response.data,
          );
          if (error.response.status === 401) {
            console.log("Invalid Token:", token); // Log the token for debugging
          }
        } else if (error.request) {
          console.error(
            "Request was made but no response received:",
            error.request,
          );
        } else {
          console.error("Error setting up token verification:", error.message);
        }
        localStorage.removeItem("token");
        setUser(null);
        setIsSubscribed(false);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const login = useCallback(
    async (email, password) => {
      console.log("Login function called with:", email);
      try {
        const response = await axios.post("/api/login", {
          email,
          password,
        });
        const { token } = response.data;
        if (token) {
          localStorage.setItem("token", token);
          await fetchUser();
        } else {
          console.error("Error: Login response did not contain a token.");
        }
      } catch (error) {
        if (error.response) {
          console.error(
            "Login failed with status:",
            error.response.status,
            "Response data:",
            error.response.data,
          );
        } else if (error.request) {
          console.error(
            "Login attempted but no response received:",
            error.request,
          );
        } else {
          console.error("Error setting up the login request:", error.message);
        }
        throw error;
      }
    },
    [fetchUser],
  );

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setUser(null);
    setIsSubscribed(false);
  }, []);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      user,
      isSubscribed,
      login,
      logout,
      fetchUser,
      loading,
    }),
    [user, isSubscribed, login, logout, fetchUser, loading],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
