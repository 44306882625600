import { useEffect, useState } from "react";

const SoundPlayer = ({
  soundFile,
  isPlaying,
  mute,
  onEnd,
  panningPosition,
}) => {
  const [audioContext, setAudioContext] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);

  useEffect(() => {
    if (!audioContext) {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(context);

      fetch(soundFile)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to fetch sound file: ${response.statusText}`,
            );
          }
          return response.arrayBuffer();
        })
        .then((data) => context.decodeAudioData(data))
        .then((buffer) => setAudioBuffer(buffer))
        .catch((error) => console.error("Audio loading error:", error));
    }
  }, [soundFile, audioContext]);

  useEffect(() => {
    if (audioContext && audioBuffer && isPlaying && !mute) {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      const panner = audioContext.createStereoPanner(); // Use StereoPannerNode
      panner.pan.value = panningPosition; // -1 for left, 1 for right

      source.connect(panner).connect(audioContext.destination);
      source.start(0);

      source.onended = onEnd;
    }
  }, [isPlaying, mute, audioContext, audioBuffer, onEnd, panningPosition]);

  return null;
};

export default SoundPlayer;
