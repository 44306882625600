import React from "react";
import { Link } from "react-router-dom";
import "./styles/Home.css";
import Footer from "./Footer";
import Header from "./Header";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <Header />

      <main className="privacy-policy-main mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4">
        <h1 className="text-xl font-bold mb-6">Privacy Policy</h1>

        <p className="mt-2 text-base">
          At RealignMind.com, operated by Realign LLC ("we," "us," or "our"), we
          value your privacy and are committed to protecting your personal
          information. This Privacy Policy explains how we collect, use, and
          safeguard your data when you use our website, mobile application, and
          related services (collectively "the Platform").
        </p>

        <h2 className="text-lg font-semibold mt-4">
          1. Information We Collect
        </h2>
        <p className="mt-2 text-base">
          We collect the following types of information:
        </p>
        <ul className="list-disc list-inside mt-2 text-base">
          <li>
            <strong>Personal Information:</strong> This includes your name,
            email address, and other identifying information you provide when
            creating an account, contacting us, or using the Platform.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you interact with
            the Platform, such as session activity, IP addresses, and device
            details.
          </li>
          <li>
            <strong>Cookies:</strong> Small text files stored on your device to
            enhance your user experience and track your preferences.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">
          2. How We Use Your Information
        </h2>
        <p className="mt-2 text-base">
          We use your information for the following purposes:
        </p>
        <ul className="list-disc list-inside mt-2 text-base">
          <li>To provide and improve our services.</li>
          <li>
            To communicate with you regarding updates, promotions, or support.
          </li>
          <li>
            To monitor and analyze usage patterns to improve the Platform.
          </li>
          <li>
            To ensure compliance with our Terms of Service and applicable laws.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">3. Data Sharing</h2>
        <p className="mt-2 text-base">
          We do not sell or rent your personal information. However, we may
          share your information with trusted third parties, including:
        </p>
        <ul className="list-disc list-inside mt-2 text-base">
          <li>
            <strong>Service Providers:</strong> Companies that assist us in
            operating the Platform, such as hosting, analytics, and payment
            processing.
          </li>
          <li>
            <strong>Legal Obligations:</strong> If required to comply with legal
            processes, enforce our terms, or protect the rights and safety of
            our users.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">4. Data Security</h2>
        <p className="mt-2 text-base">
          We take appropriate measures to protect your personal information from
          unauthorized access, disclosure, or misuse. However, no method of
          transmission over the Internet or electronic storage is completely
          secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-lg font-semibold mt-4">5. Your Rights</h2>
        <p className="mt-2 text-base">You have the right to:</p>
        <ul className="list-disc list-inside mt-2 text-base">
          <li>Access the personal information we hold about you.</li>
          <li>Request corrections to your information.</li>
          <li>Delete your account and associated data.</li>
          <li>Opt out of non-essential communications.</li>
        </ul>

        <h2 className="text-lg font-semibold mt-4">6. Third-Party Links</h2>
        <p className="mt-2 text-base">
          The Platform may contain links to third-party websites or services. We
          are not responsible for the privacy practices or content of these
          external sites. We encourage you to review their privacy policies
          before providing any personal information.
        </p>

        <h2 className="text-lg font-semibold mt-4">
          7. Changes to This Policy
        </h2>
        <p className="mt-2 text-base">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with the updated "Last Updated" date. By
          continuing to use the Platform, you agree to the updated Privacy
          Policy.
        </p>

        <h2 className="text-lg font-semibold mt-4">8. Contact Us</h2>
        <p className="mt-2 text-base">
          If you have any questions or concerns about this Privacy Policy or
          your personal information, please contact us at{" "}
          <Link to="/contact">Contact Us</Link>.
        </p>

        <p className="mt-2 text-base">Last Updated: December 03, 2024</p>
      </main>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
