import React from "react";

function Footer() {
  return (
    <footer className="bg-background text-text text-center p-4">
      <p>&copy; {new Date().getFullYear()} Realign LLC</p>
      <nav>
        <ul className="flex justify-center space-x-4">
          <li>
            <a
              href="/terms-of-service"
              className="text-primary hover:text-accent"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a href="/contact" className="text-primary hover:text-accent">
              Contact
            </a>
          </li>
          <li>
            <a href="/disclaimer" className="text-primary hover:text-accent">
              Disclaimer
            </a>
          </li>
          <li>
            <a
              href="/emergency-support"
              className="text-primary hover:text-accent"
            >
              Emergency Support
            </a>
          </li>
          <li>
            <a
              href="/privacy-policy"
              className="text-primary hover:text-accent"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
