import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import posthog from "posthog-js";

function RegistrationForm() {
  const [isRegistering, setIsRegistering] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const handleRegistration = async (event) => {
    event.preventDefault();
    try {
      // Perform registration API call
      await axios.post("/api/register", {
        email,
        password,
        firstName,
        lastName,
      });
      toast.success("Registration successful! Please log in.");
      setIsRegistering(false); // Switch to login form
    } catch (error) {
      console.error("Registration failed:", error);
      toast.error(
        error.response?.data?.error || "An error occurred during registration.",
      );
    }
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      // Perform login API call
      await axios.post("/api/login", { email, password });
      posthog.capture("user_logged_in", {
        login_type: "email",
      });
      posthog.identify(email, {
        email: email,
      });
      toast.success("Login successful!");
      navigate("/dashboard"); // Redirect to the user's dashboard or any protected route
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(
        error.response?.data?.error || "An error occurred during login.",
      );
    }
  };
  return (
    <div className="flex flex-col items-center">
      <form
        onSubmit={isRegistering ? handleRegistration : handleLogin}
        className="flex flex-col max-w-md w-full"
      >
        {isRegistering && (
          <>
            <div className="mb-4">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-text"
              >
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indgo-200"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-200"
              />
            </div>
          </>
        )}
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-200"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-200"
          />
        </div>
        <button
          type="submit"
          className="mt-4 bg-accent text-white font-semibold py-2 px-4 rounded-md hover:bg-primary"
        >
          {isRegistering ? "Register" : "Log In"}
        </button>
      </form>
    </div>
  );
}

export default RegistrationForm;
