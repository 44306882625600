import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function Subscribe() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    script.onload = () => {
      console.log("Stripe script loaded successfully.");
    };
    script.onerror = () => {
      console.error("Error loading the Stripe script.");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <main className="flex-grow mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl mt-4 mb-4 flex flex-col items-center">
        <p className="text-lg mb-4 text-center text-gray-700">
          Transform your mental health journey with our state-of-the-art EMDR
          therapy app. Choose the plan that fits your needs and start your path
          to healing today.
        </p>

        <div className="text-center mb-10">
          <div className="flex justify-center">
            <stripe-pricing-table
              pricing-table-id="prctbl_1QSXEUEqsAtq40cmf24qfWWH"
              publishable-key="pk_live_51Pe8ZdEqsAtq40cmhweccJqRXTghuVwQrwZbEbnO22TgV2VgyAWswqrPCVIqgMoRxqya5wRcHOZSFAONAOfPBJ3b00KUQhRKFx"
            ></stripe-pricing-table>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Subscribe;
