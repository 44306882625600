import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog from "posthog-js";

// Initialize PostHog
posthog.init("phc_RsHSxsff07YRxx92pNJw6QyNwrqhcF6HX7WrpH4R4bM", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

// Find the root container element
const container = document.getElementById("root");
// Create a root
const root = createRoot(container);
// Initial render: render the App component to the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// For performance measurements
reportWebVitals();
