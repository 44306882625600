import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuthValidation } from "../hooks/useAuthValidation";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import Modal from "./Modal";
import DotMovement from "./DotMovement"; // Ensure this is the working final version
import DotContext from "../contexts/DotContext";
import MoodTracker from "./MoodTracker";
import { useAuth } from "../contexts/AuthContext";
import { useScriptFetcher } from "../hooks/useScriptFetcher";
import "./styles/GuidedSession.css";

function ARTSession() {
  const navigate = useNavigate();
  const { user, isSubscribed } = useAuth();
  const isValidated = useAuthValidation();
  const { script, isLoading, fetchScript } = useScriptFetcher(navigate);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [showDotMovement, setShowDotMovement] = useState(false);
  const [preSessionMood, setPreSessionMood] = useState(null);
  const [moodModalVisible, setMoodModalVisible] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    bgColor,
    dotColor,
    dotSize,
    dotSpeed,
    isPaused,
    setIsPaused,
    setSidebarVisible,
    sidebarVisible,
    exitSession,
    setExitSession,
    soundFile, // Get soundFile from context
    mute, // Get mute from context
  } = useContext(DotContext);

  useEffect(() => {
    if (!isValidated) {
      if (!user) {
        navigate("/login", { replace: true, state: { from: "/sessions/art" } });
      } else if (!isSubscribed) {
        navigate("/subscribe", {
          replace: true,
          state: { from: "/sessions/art" },
        });
      }
    }
  }, [isValidated, user, isSubscribed, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.user_id);
      } catch (error) {
        toast.error("Invalid token. Please log in again.");
        localStorage.removeItem("token");
        navigate("/login", { replace: true });
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    if (!user || !isSubscribed) return;
    const abortController = new AbortController();

    const initSession = async () => {
      await fetchScript(1);
      if (!abortController.signal.aborted) {
        setMoodModalVisible(true);
      }
    };

    initSession();

    return () => {
      abortController.abort();
      console.log("Cleaning up ARTSession...");
    };
  }, [fetchScript, user, isSubscribed]);

  const endSession = useCallback(() => {
    setShowDotMovement(false);
    setIsModalVisible(false);
    setMoodModalVisible(true);
  }, []);

  useEffect(() => {
    if (exitSession) {
      endSession();
      setExitSession(false);
    }
  }, [exitSession, setExitSession, endSession]);

  const handleOptionClick = (nextStepId) => {
    if (nextStepId === null) {
      endSession();
    } else if (script) {
      const nextStepIndex = script.steps.findIndex(
        (step) => step.id === nextStepId,
      );
      if (nextStepIndex !== -1) {
        setCurrentStepIndex(nextStepIndex);
      } else {
        console.error("Next step not found in the script.");
      }
    }
  };

  const handleMoodSubmit = async (mood, note, type) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Authentication token not found. Please log in again.");
        navigate("/login", { replace: true });
        return;
      }

      const payload =
        type === "pre"
          ? {
              user_id: user.id,
              mood_pre: mood,
              comment_pre: note,
              session_type: "ART",
            }
          : {
              mood_post: mood,
              comment_post: note,
            };

      const apiURL =
        type === "pre" ? "/api/sessions" : `/api/sessions/${sessionId}`;
      const method = type === "pre" ? "post" : "patch";

      const response = await axios({
        method,
        url: apiURL,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (type === "pre") {
        setSessionId(response.data.session_id);
        setIsModalVisible(true);
        setMoodModalVisible(false);
        setPreSessionMood(mood);
        toast.success("Session started successfully");
      } else if (type === "post") {
        setSidebarVisible(false);
        toast.success("Session completed successfully");
        navigate("/session-end");
      }
    } catch (error) {
      if (axios.isCancel(error)) return;
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        toast.error("Network error, please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextClick = () => {
    if (script && currentStepIndex < script.steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setIsModalVisible(false);
      setShowDotMovement(true);
    } else {
      endSession();
    }
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const toggleNav = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const endDotMovement = () => {
    setShowDotMovement(false);
    setIsModalVisible(true);
  };

  const renderModalContent = () => {
    if (
      !script ||
      !script.steps ||
      script.steps.length === 0 ||
      currentStepIndex >= script.steps.length
    ) {
      return null;
    }

    const currentStep = script.steps[currentStepIndex];
    const isLastStep = currentStep.options.some(
      (option) => option.next_step_id === null,
    );

    const ModalContainer = ({ children }) => (
      <div className="fixed inset-0 flex items-center justify-center z-20">
        <div className="bg-background rounded-lg shadow-lg p-6 max-w-md mx-auto">
          {children}
        </div>
      </div>
    );

    if (isLastStep) {
      return (
        <ModalContainer>
          <p className="text-text mb-4">{currentStep.text}</p>
          <div className="flex flex-wrap gap-2">
            {currentStep.options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionClick(option.next_step_id)}
                className="bg-accent text-white font-semibold py-2 px-4 rounded hover:bg-primary transition duration-200"
              >
                {option.text}
              </button>
            ))}
          </div>
        </ModalContainer>
      );
    }

    if (!isValidated) {
      return (
        <ModalContainer>
          <p className="text-text">Loading...</p>
        </ModalContainer>
      );
    }

    return (
      <ModalContainer>
        <p className="text-text mb-4">{currentStep.text}</p>
        <div className="flex flex-wrap gap-2">
          {currentStep.options && currentStep.options.length > 0 ? (
            currentStep.options
              .sort((a, b) => a.id - b.id)
              .map((option) => (
                <button
                  key={option.id}
                  onClick={() => handleOptionClick(option.next_step_id)}
                  className="bg-accent text-white font-semibold py-2 px-4 rounded hover:bg-primary transition duration-200"
                >
                  {option.text}
                </button>
              ))
          ) : (
            <button
              onClick={handleNextClick}
              className="bg-accent text-white font-semibold py-2 px-4 rounded hover:bg-primary transition duration-200"
            >
              Next
            </button>
          )}
        </div>
      </ModalContainer>
    );
  };

  if (isLoading || !user || !isSubscribed) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="guided-session-page">
      {!sidebarVisible && (
        <button
          onClick={toggleNav}
          className="hamburger-menu"
          aria-label="toggle navigation"
        >
          <span className="hamburger">&#9776;</span>
        </button>
      )}

      {moodModalVisible && (
        <MoodTracker
          onSubmit={(mood, note) =>
            handleMoodSubmit(mood, note, preSessionMood ? "post" : "pre")
          }
        />
      )}

      {!moodModalVisible && isModalVisible && (
        <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
          {renderModalContent()}
        </Modal>
      )}

      {showDotMovement && (
        <div className="dot-container">
          <button
            className="absolute bottom-4 bg-accent text-white py-2 px-6 rounded hover:bg-primary transition duration-200 z-50"
            onClick={togglePause}
          >
            {isPaused ? "Resume" : "Pause"}
          </button>
          <DotMovement
            shouldRun={showDotMovement}
            isPaused={isPaused}
            bgColor={bgColor}
            dotColor={dotColor}
            dotSize={dotSize}
            dotSpeed={dotSpeed}
            onCompletion={endDotMovement}
            soundFile={soundFile} // Pass sound file
            mute={mute} // Pass mute state
          />
        </div>
      )}
    </div>
  );
}

export default ARTSession;
