// useScriptFetcher.js
import { useState, useCallback } from "react";
import axios from "axios";

export function useScriptFetcher(navigate) {
  const [script, setScript] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Memoize fetchScript
  const fetchScript = useCallback(
    async (scriptId) => {
      console.log("Fetching script: ", scriptId);
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login", { replace: true });
          return;
        }

        const response = await axios.get(`/api/scripts/${scriptId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setScript(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request was canceled");
        } else if (error.response && error.response.status === 401) {
          // Token might be invalid or expired
          localStorage.removeItem("token");
          navigate("/login", { replace: true });
        } else {
          console.error("An error occurred while fetching the script:", error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [navigate], // Dependencies
  );

  return { script, isLoading, fetchScript };
}
