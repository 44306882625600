import React from "react";

const DotContext = React.createContext({
  dotColor: "#333333",
  setDotColor: () => {},
  bgColor: "#FFF8F0",
  setBgColor: () => {},
  dotSize: 20,
  setDotSize: () => {},
  dotSpeed: 90,
  setDotSpeed: () => {},
  sidebarVisible: false,
  setSidebarVisible: () => {},
  shouldRun: false,
  setShouldRun: () => {},
  isPaused: false,
  setIsPaused: () => {},
  exitSession: false,
  setExitSession: () => {},
  soundFile: "/sounds/Synth_Tick_C_lo.wav", 
  setSoundFile: () => {},
  mute: false,
  setMute: () => {},
});

export default DotContext;
